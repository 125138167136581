document.addEventListener("DOMContentLoaded", function () {
    /*  search */
	const footerSearch = document.getElementById('footerSearch')
	if (footerSearch) {
        const wrapper = footerSearch.closest('.footer__search-wrapper')
        if (wrapper) {
            const searchResultBlock = wrapper.querySelector('.footer__search_result')
            const noResultsMessage = searchResultBlock.querySelector('.footer__search_no-results')

            function search() {
                searchResultBlock.classList.toggle('footer-none', footerSearch.value.length == 0);
                let filter = footerSearch.value.toUpperCase()
                let ul = wrapper.querySelectorAll('.search-list')
                let totalResults = 0

                ul.forEach(item => {
                    let li = item.getElementsByTagName('li')
                    for (let i = 0; i < li.length; i++) {
                        let a = li[i].querySelector('.search-name')
                        if (a.innerHTML.toUpperCase().indexOf(filter) > -1) {
                            li[i].classList.remove('footer-none')
                            totalResults++
                        } else {
                            li[i].classList.add('footer-none')
                        }
                    }
                })
                noResultsMessage.classList.toggle('footer-none', totalResults > 0);
            }
            footerSearch.addEventListener('input', search)

            document.addEventListener('click', (event) => {
                if (!wrapper.contains(event.target)) {
                    searchResultBlock.classList.add('footer-none')
                }
            })
        }
	}
    /*  end search  */  


    /*  footer accordion  */
	const footerAcc = document.getElementsByClassName('footer__nav_acc')
	for (let i = 0; i < footerAcc.length; i++) {
        if(footerAcc[i]) {
            footerAcc[i].addEventListener('click', function () {
                const accContent = this.nextElementSibling 
                console.log(accContent)
                if (accContent.classList.contains('active')) {
                    accContent.classList.remove('active');
                    this.classList.remove('active');
                    accContent.style.maxHeight = '0'; 
                } else {
                    accContent.classList.add('active');
                    this.classList.add('active');
                    const contentHeight = accContent.scrollHeight;
                    accContent.style.maxHeight = `${contentHeight}px`;
                }
            })
        }
	}
	/*  end footer accordion   */
})